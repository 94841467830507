<template>
  <admin>
    <page-header
      :back="{
        to: {
          name: 'dealerParts.masterlist.index'
        },
        text: trans('Master List'),
      }"
    >
      <template v-slot:title><span v-text="trans('Upload Master List')"></span></template>
    </page-header>

    <div class="row">
      <div class="col-lg-9 col-12">
        <v-card>
          <v-card-title>File Information</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  label="Upload File"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  outlined
                  :error-messages="errorMessage"
                  v-model="file"
                ></v-file-input>
                <br />
                <can :code="['admin_parts']">
                  <p>Click <a :href="sampleFileUrlAdmin" download>here</a> to the download sample file.</p>
                </can>
                <can :code="['dealer_parts']">
                  <p>Click <a :href="sampleFileUrlDealer" download>here</a> to the download sample file.</p>
                </can>
              </v-col>
            </v-row>
            <div class="d-flex justify-end my-6" flat tile>
              <v-btn
                large
                exact
                color="green darken-1"
                class="ma-1 white--text"
                @click="saveOnly"
                :disabled="resources.loadingSave || resources.loadingSaveAndExit"
              >
                <v-icon left>mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-btn
                large
                exact
                color="green"
                class="ma-1 white--text"
                @click="saveAndExit"
                :disabled="resources.loadingSave || resources.loadingSaveAndExit"
              >
                <v-icon left>mdi-content-save</v-icon>
                Save and Exit
              </v-btn>
              <v-btn
                large
                exact
                color="warning"
                class="ma-1 white--text"
                :to="{ name: 'dealerParts.masterlist.index' }"
              >
                <v-icon left>mdi-close</v-icon>
                Cancel
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </admin>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      resources: {
        loadingSave: false,
        loadingSaveAndExit: false,
      },
      file: {},
      saveAndExitTrigger: false,
      errorMessage: "",
      sampleFileUrlDealer:
        process.env.VUE_APP_API_BASE_URL.replace("api/", "") + "sample-files/master-parts-selling-dealer.xlsx",
      sampleFileUrlAdmin:
        process.env.VUE_APP_API_BASE_URL.replace("api/", "") + "sample-files/master-parts-selling-admin.xlsx",
    };
  },
  methods: {
    ...mapActions({
      uploadMasterList: "masterlist/uploadMasterList",
      showDialog: "dialog/show",
      hideDialog: "dialog/hide",
    }),
    async submit() {
      this.showDialog({
        title: trans("Confirm Upload"),
        text: trans(`Are you sure you want to proceed uploading <strong>${this.file.name}</strong>?`),
        buttons: {
          cancel: {
            text: trans("Cancel"),
            callback: () => {
              this.errorMessage = "";
              this.resources.loadingSave = false;
              this.resources.loadingSaveAndExit = false;
              this.hideDialog();
            },
          },
          action: {
            color: "info",
            text: trans("Submit"),
            callback: () => {
              this.confirmSaveMasterlist();
              this.hideDialog();
            },
          },
        },
      });
    },

    async confirmSaveMasterlist() {
      let fd = new FormData();
      fd.append("file", this.file);

      await this.uploadMasterList(fd).then((status) => {
        if (status) {
          this.errorMessage = "";
          this.resources.loadingSave = false;
          this.resources.loadingSaveAndExit = false;
          if (this.saveAndExitTrigger) {
            this.$router.push({ name: "dealerParts.masterlist.index" });
          } //endif
        } else {
          this.resources.loadingSave = false;
          this.resources.loadingSaveAndExit = false;
        }
      });
    },

    async saveOnly() {
      this.saveAndExitTrigger = false;
      // check if file has value
      if (this.file.size > 0) {
        this.resources.loadingSave = true;
        await this.submit();
      } else {
        this.errorMessage = "File is required";
      }
    },
    async saveAndExit() {
      // check if file has value
      this.saveAndExitTrigger = true;
      if (this.file.size > 0) {
        this.resources.loadingSave = true;
        this.resources.loadingSaveAndExit = true;
        await this.submit();
      } else {
        this.errorMessage = "File is required";
      }
    },
  },
};
</script>

<style></style>
